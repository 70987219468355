/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { AppProps } from 'next/app';
import { RootApp } from './RootApp';
import '../styles/Base.scss';
import '../styles/Slick.scss';
import '@doda/io-react/common/index.css';

/**
 * 上位コンポーネント
 * @remarks
 * 全てのコンポーネントがAppコンポーネントを継承する
 * 全ページで共通して実行させたい処理やレイアウトはRootAppコンポーネントを更新してください。
 * @param Component コンポーネント
 * @param pageProps ページプロパティ
 * @returns Appコンポーネント
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const App: FC<AppProps<any>> = ({ Component, ...props }) => {
  return (
    <RootApp>
      <Component {...props} />
    </RootApp>
  );
};

export default App;
