import {
  WJOFL_CHANGE,
  WJOFL_DIRECT,
  WJOFL_CHANGEDIRECT,
  WJOFL_NOEXISTBTN,
} from '@doda/common/src/constants/properties/const';
import { IncomingHttpHeaders } from 'http';
import {
  PageEventProps,
  Option,
} from '@doda/common/src/interfaces/app/googleTagManager';
import { getCookie } from './httpCookieUtil';

export const dataLayer = (option: PageEventProps | Option) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(option);
};

export const tagManagerPageView = (url: string) => {
  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };

  dataLayer(pageEvent);
  return pageEvent;
};

/**
 * JobIdTypeCaseを取得する
 * @param {string} docType 原稿企画名
 * @param {string} jobOfferKind 求人種別
 */
export const getJobIdTypeCase = (
  docType: string,
  jobOfferKind: string
): string => {
  if (docType.includes('DR')) {
    // 原稿企画名に"DR"が含まれる場合
    return 'DR案件';
  }
  switch (jobOfferKind) {
    case WJOFL_CHANGE:
      return '紹介応募案件（掲載前スカウト含む）';
    case WJOFL_CHANGEDIRECT:
      return '直接応募・紹介応募案件（2つボタン）';
    case WJOFL_DIRECT:
      return '直接応募案件（DRのぞき）';
    case WJOFL_NOEXISTBTN:
      return 'ボタンなし';
    default:
      return '';
  }
};

/**
 * JobIdScoutを取得する
 * @param {string} docType 原稿企画名
 * @param {string} jobOfferKind 求人種別
 */
export const getJobIdScout = (
  docType: string,
  jobOfferKind: string
): string => {
  // 原稿企画名に"DR"が含まれるか
  const hasDr = docType.includes('DR');

  if (!hasDr && jobOfferKind === WJOFL_CHANGEDIRECT) {
    return 'スカウト（DR除く）';
  }
  return 'スカウト以外（DR含む）';
};

/**
 * JobIdEntryButtonを取得する
 * @param {string} jobOfferKind 求人種別
 */
export const getJobIdEntryButton = (jobOfferKind: string) => {
  switch (jobOfferKind) {
    case WJOFL_CHANGE:
      return '紹介';
    case WJOFL_CHANGEDIRECT:
      return '直接+紹介';
    case WJOFL_DIRECT:
      return '直接';
    case WJOFL_NOEXISTBTN:
      return 'ボタンなし';
    default:
      return '';
  }
};

/**
 * cookieの_ga値（「GA〇.〇」と「.」を除外した数値のみ）を取得する
 * @param {IncomingHttpHeaders} headers
 * @returns {string} GAID
 */
export function getGaidNumber(headers: IncomingHttpHeaders): string {
  const cookieStr = headers.cookie ?? '';
  const gid = getCookie(cookieStr, '_ga');
  // _ga値の先頭「GA〇.〇」は画面によって異なる可能性があるので、除外して判定
  return gid != null
    ? gid.replace(/GA[0-9]\.[0-9]\./, '').replace(/[^0-9]/g, '')
    : '';
}
