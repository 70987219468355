import { FC, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import NextHead from 'next/head';
import { tagManagerPageView } from '../utils/googleTagManagerUtil';

/**
 * 全ページ共通処理用コンポーネント
 * 本コンポーネント内ではstoreに接続できません。
 */
export const RootApp: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    // Initiate GTM
    const handleRouteChange = (url: string) => tagManagerPageView(url);
    // pathが変更した際のeventListenerを設定
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
    // TODO: 修正してdisableを取り除く
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NextHead>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
        />
      </NextHead>
      {children}
    </>
  );
};
