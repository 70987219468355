// #選択タブ 募集要項(求人詳細・応募方法)タブ判定用#
export const TAB_JOBDETAIL = 'jd';
// #選択タブ 求人PR(Pick up)タブ判定用#
export const TAB_PR = 'pr';
// #選択タブ 口コミタブ判定用#
export const TAB_RV = 'rv';
// #選択タブ 会社説明会タブ判定用#
export const TAB_SEMINAR = 'seminar';
// #選択タブ 写真タブ判定用#
export const TAB_PHOTOS = 'photos';

// 以下はAPIレスポンスでは返却されEで返却されている
// #S原稿判定用#
export const WCNSID_S = '3';
// #T原稿判定用#
export const WCNSID_T = '2';
// #A原稿判定用#
export const WCNSID_A = '4';
// #B原稿判定用#
export const WCNSID_B = '5';
// #C原稿判定用#
export const WCNSID_C = '6';
// #D原稿判定用#
export const WCNSID_D = '7';
// #E原稿判定用#
export const WCNSID_E = '8';

// 汎用コードマスタID その他のID#
export const ID_OTHERS_OLD = '11,12,13,14,100';
// 汎用コードマスタID その他のID#
export const ID_OTHERS = '19,57,58,59,64';
// 汎用コードマスタID その他のID(19以外)#
export const ID_OTHERS_EXCPT_19 = '64,57,58,59';

/** 希望年収HA */
export const STR_HA_NO_SELECT = '0';
// #求人種類 転職支援#
export const WJOFL_CHANGE = '0';
// #求人種類 直接応募#
export const WJOFL_DIRECT = '2';
// #求人種類 転職支援・直接応募#
export const WJOFL_CHANGEDIRECT = '1';
// #求人種類 ボタンなし
export const WJOFL_NOEXISTBTN = '4';
// #求人詳細用 外部公開区分#
export const OUTSIDE_OPEN = '0';
// #求人詳細用 スカウト区分#
export const SCOUNT_KBN = '1';
// #求人詳細用 その他募集職種表示の表示対象とするか否か（0:しない 1:する）
export const OTHERJOB_DISP = '1';

/** キーワード(含む/含まない)の検索範囲のデフォルト値 1：求人内容すべて */
export const KNI_SEARCHFLG_ALL = '1';

// 海外・全国
export const NATIONAL_AREA_NUMBER = '1';
export const ABROAD_AREA_NUMBER = '99';
